///////////////////////////////////////////////////////////////////////////////////MODULES
import { connect } from 'react-redux';
import { hmToMs } from '@ibiliaze/time';
import { CustomCard, Row, Col } from '@ibiliaze/reactstrap';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { postItem } from '../../actions/wishlist';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const Services = ({ postItem }) => {
  // onClick functions
  const onAddClick = service => {
    postItem({
      serviceName: service.serviceName,
      price: service.price,
      duration: hmToMs(service.duration),
      currency: constants.services.currency,
    });
  };

  // JSX
  return (
    <section id='services-section' className='page p-t-m'>
      <h1 className='t-align-c'>Pricing</h1>
      <Row>
        {constants.services.services.map((service, i) => (
          <Col key={i} md={3}>
            <CustomCard
              imgSrc={service.imageSrc}
              title={service.serviceName}
              // text={service.description}
              hoverTitle='Səbət at'
              clickedTitle={service.serviceName}
              onCardClickHandler={onAddClick}
              args={service}
            />
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default connect(null, { postItem })(Services);

///////////////////////////////////////////////////////////////////////////////////MODULES
import { Col, Row, CustomToast } from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const About = () => (
  <section id='about-section' className='page p-t-m'>
    <h1 className='t-align-c'>Məlumatlar</h1>
    <Row>
      {constants.aboutUs.map((content, i) => (
        <Col key={i} md={4}>
          <CustomToast header={content.header} body={content.body} icon={content.icon} />
        </Col>
      ))}
    </Row>
  </section>
);

export default About;

///////////////////////////////////////////////////////////////////////////////////MODULES
import { Banner as CustomBanner } from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const Banner = () =>
  !!constants.banner && (
    <section className='page p-t-m'>
      <CustomBanner {...constants.banner} />
    </section>
  );

export default Banner;

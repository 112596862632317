///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { connect } from 'react-redux';
import {
  CustomButton,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  CustomInput,
  CloseButton,
} from '@ibiliaze/reactstrap';
import { msToHm } from '@ibiliaze/time';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { deleteItem, resetWishlist } from '../../actions/wishlist';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const Wishlist = ({ toggle, modal, wishlist, deleteItem }) => {
  // State
  const [inputs, setInputs] = useState({ clientName: '', email: '', phone: '', selectedTime: '' });

  // onChange functions
  const onInputsChange = e => setInputs({ ...inputs, [e.target.name]: e.target.value });

  // onClick functions
  const onRemoveClick = serviceName => deleteItem(serviceName);
  const onBookClick = e => {
    e.preventDefault();
    window.open(
      `https://wa.me/${constants.phone1}?text=${`Hər vaxtınız xeyir.%0AMənim sifarişim:%0A${Object.keys(wishlist.items)
        .map(item => ` • ${item}`)
        .join('%0A')}%0A%0A${inputs.clientName}%0A${inputs.email}%0A${inputs.phone}%0A%0ARezervasiya vaxtı: ${
        inputs.selectedTime
      }`}`
    );
  };

  // JSX
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <Form onSubmit={onBookClick}>
        <ModalHeader toggle={toggle}>Səbət</ModalHeader>
        <ModalBody>
          <div className='xs-text'>
            <Table borderless>
              <thead>
                <tr>
                  <th>Servis</th>
                  <th>Məbləğ</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(wishlist.items).map((serviceName, index) => {
                  return (
                    <tr key={index}>
                      <td>{serviceName}</td>
                      <td>
                        {wishlist.items[serviceName].price} {wishlist.currency}
                      </td>
                      <td>
                        <CloseButton onClick={_ => onRemoveClick(serviceName)} />
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <th>Ümumi məbləğ</th>
                  <th>
                    {wishlist.total} {wishlist.currency}
                  </th>
                </tr>
                <tr>
                  <th>Müddət</th>
                  <th>{msToHm(wishlist.duration)}</th>
                </tr>
              </tbody>
            </Table>
          </div>
          <FormGroup>
            <Label>Ad</Label>
            <CustomInput
              required
              name='clientName'
              placeholder='Ad'
              value={inputs.clientName}
              onChange={onInputsChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <CustomInput required name='email' placeholder='Email' value={inputs.email} onChange={onInputsChange} />
          </FormGroup>
          <FormGroup>
            <Label>Telefon</Label>
            <CustomInput
              required
              name='phone'
              type='tel'
              placeholder='Telefon'
              value={inputs.phone}
              onChange={onInputsChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Rezervasiya</Label>
            <CustomInput
              required
              type='datetime-local'
              name='selectedTime'
              value={inputs.selectedTime}
              onChange={onInputsChange}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            type='submit'
            disabled={Object.keys(wishlist.items).length === 0 ? true : false}
            color='primary'
          >
            Sifarişi rəsmiləşdir
          </CustomButton>
          <CustomButton onClick={_ => toggle()}>Ləğv et</CustomButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => ({ wishlist: state.wishlist });

export default connect(mapStateToProps, { deleteItem, resetWishlist })(Wishlist);

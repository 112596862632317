/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from '../actions/types/types';
///////////////////////////////////////////////////////////////////////ITEMS DEFAULT STATE
const wishlistDefaultState = { items: {}, total: 0, duration: 0, currency: '' };
/////////////////////////////////////////////////////////////////////////////ITEMS REDUCER
const wishlist = (state = wishlistDefaultState, action) => {
  switch (action.type) {
    // GET request
    case types.GET_ITEMS_SUCCESS:
      return { ...action.wishlist };
    case types.GET_ITEMS_FAILURE:
      return { ...state };

    // POST request
    case types.POST_ITEM_SUCCESS:
      return {
        items: { ...state.items, [action.item.serviceName]: action.item },
        currency: action.item.currency,
        total: action.item.serviceName in state.items ? state.total : state.total + action.item.price,
        duration: action.item.serviceName in state.items ? state.duration : state.duration + action.item.duration,
      };
    case types.POST_ITEM_FAILURE:
      return { ...state };

    // DELETE request
    case types.DELETE_ITEM_SUCCESS:
      const wishlist = state;
      const currency = wishlist.items[action.serviceName].currency;
      const price = wishlist.items[action.serviceName].price;
      const serviceDuration = wishlist.items[action.serviceName].duration;
      const total = wishlist.total;
      const duration = wishlist.duration;
      delete wishlist.items[action.serviceName];
      return {
        items: { ...wishlist.items },
        currency,
        total: total - price,
        duration: duration - serviceDuration,
      };
    case types.DELETE_ITEM_FAILURE:
      return { ...state };

    // Reset wishlist
    case types.RESET_BASKET_SUCCESS:
      return { ...wishlistDefaultState };
    case types.RESET_BASKET_FAILURE:
      return { ...wishlistDefaultState };

    default:
      return state;
  }
};

export default wishlist;

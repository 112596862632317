///////////////////////////////////////////////////////////////////////////////////MODULES
import { BrowserRouter, Route, Routes } from 'react-router-dom';
/////////////////////////////////////////////////////////////////////////////////////PAGES
import HomePage from '../components/Pages/HomePage';
import PrivacyPolicyPage from '../components/Pages/legal/PrivacyPolicyPage';
import CookiesPolicyPage from '../components/Pages/legal/CookiesPolicyPage';
import TermsConditions from '../components/Pages/legal/TermsConditions';
import DisclaimerPage from '../components/Pages/legal/DisclaimerPage';
import ReturnPolicyPage from '../components/Pages/legal/ReturnPolicyPage';
import NotFoundPage from '../components/Pages/NotFoundPage';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Header from '../components/Layout/Header';
import ChatWidget from '../components/Layout/ChatWidget';
import Footer from '../components/Layout/Footer';
//////////////////////////////////////////////////////////////////////////////////////////

const AppRouter = () => (
  <BrowserRouter>
    <>
      <Header />
      <Routes>
        {/* Pages */}
        <Route exact path='/' element={<HomePage />} />
        {/* Legal Pages */}
        <Route exact path='/privacy-policy' element={<PrivacyPolicyPage />} />
        <Route exact path='/cookie-policy' element={<CookiesPolicyPage />} />
        <Route exact path='/terms-conditions' element={<TermsConditions />} />
        <Route exact path='/disclaimer' element={<DisclaimerPage />} />
        <Route exact path='/return-policy' element={<ReturnPolicyPage />} />
        {/* 404 */}
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
      <Footer />
      <ChatWidget />
    </>
  </BrowserRouter>
);

export default AppRouter;

/////////////////////////////////////////////////////////////////////////////////////TYPES
import {
  GET_ITEMS_SUCCESS,
  GET_ITEMS_FAILURE,
  POST_ITEM_SUCCESS,
  POST_ITEM_FAILURE,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAILURE,
  RESET_BASKET_SUCCESS,
  RESET_BASKET_FAILURE,
} from './types/types';
//////////////////////////////////////////////////////////////////////////////////////////

localStorage.setItem(
  'wishlist',
  localStorage.getItem('wishlist') || JSON.stringify({ items: {}, total: 0, duration: 0, currency: '' })
);

// GET
export const getItemsSuccess = wishlist => ({ type: GET_ITEMS_SUCCESS, wishlist });
export const getItemsFailure = errorMessage => ({ type: GET_ITEMS_FAILURE, errorMessage });
export const getItems = () => async dispatch => {
  try {
    const wishlist = localStorage.getItem('wishlist') || { items: {}, total: 0, currency: '' };
    dispatch(getItemsSuccess(JSON.parse(wishlist)));
  } catch (error) {
    dispatch(getItemsFailure(error.message));
  }
};

// POST
export const postItemSuccess = item => ({ type: POST_ITEM_SUCCESS, item });
export const postItemFailure = errorMessage => ({ type: POST_ITEM_FAILURE, errorMessage });
export const postItem = item => async dispatch => {
  try {
    const wishlist = JSON.parse(localStorage.getItem('wishlist'));
    localStorage.setItem(
      'wishlist',
      JSON.stringify({
        items: { ...wishlist.items, [item.serviceName]: item },
        currency: item.currency,
        total: item.serviceName in wishlist.items ? wishlist.total : wishlist.total + item.price,
        duration: item.serviceName in wishlist.items ? wishlist.duration : wishlist.duration + item.duration,
      })
    );
    dispatch(postItemSuccess(item));
  } catch (error) {
    dispatch(postItemFailure(error.message));
  }
};

// DELETE
export const deleteItemSuccess = serviceName => ({ type: DELETE_ITEM_SUCCESS, serviceName });
export const deleteItemFailure = errorMessage => ({ type: DELETE_ITEM_FAILURE, errorMessage });
export const deleteItem = serviceName => async dispatch => {
  try {
    const wishlist = JSON.parse(localStorage.getItem('wishlist'));
    const currency = wishlist.items[serviceName].currency;
    const price = wishlist.items[serviceName].price;
    const serviceDuration = wishlist.items[serviceName].duration;
    const total = wishlist.total;
    const duration = wishlist.duration;
    delete wishlist.items[serviceName];
    localStorage.setItem(
      'wishlist',
      JSON.stringify({
        items: { ...wishlist.items },
        currency,
        total: total - price,
        duration: duration - serviceDuration,
      })
    );
    dispatch(deleteItemSuccess(serviceName));
  } catch (error) {
    dispatch(deleteItemFailure(error.message));
  }
};

export const resetWishlistSuccess = () => ({ type: RESET_BASKET_SUCCESS });
export const resetWishlistFailure = errorMessage => ({ type: RESET_BASKET_FAILURE, errorMessage });
export const resetWishlist = () => async dispatch => {
  try {
    localStorage.setItem('wishlist', JSON.stringify({ items: {}, total: 0, currency: '' }));
    dispatch(resetWishlistSuccess());
  } catch (error) {
    dispatch(resetWishlistFailure(error.message));
  }
};

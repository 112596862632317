import * as icons from './icons';

export const env = process.env;

const constants = {
  cust: 'Lamya Eyvazova',
  custSm: 'lamya eyvazova',
  custJs: 'lamyaEyvazova',
  slogan: 'Gəlinlik və Gözəllik Stilisti',
  phone1: '+994554133558',
  email1: 'lamiyestilist@box.az',
  hqAddr: 'Elşən Süleymanov 49, AZ1129 Bakı',
  chatPerson: 'Lamya',
  chatMessage: `Hər vaxtınız xeyir 👋\n \nNecə kömək edə bilərik?`,
  chatStatus: `Typically replies within a few minutes`,
  mainPic: '/img/lkm.gif',
  mainPicButton: 'Bizimlə Əlaqə Yarat',
  instagram: 'lamastilist_klstudiya',
  // facebook: 'klstudiya',
  // youtube: 'UCk4RFdcml5JXqRV5lx6pbkA',
  // banner: {
  //   title: 'Warning',
  //   text: 'Warning text',
  //   bottomText: 'More warning',
  // },
  workingHrs: {
    days: {
      Mon: { start: 36000000, end: 75600000 },
      Tue: { start: 36000000, end: 75600000 },
      Wed: { start: 36000000, end: 75600000 },
      Thu: { start: 36000000, end: 75600000 },
      Fri: { start: 36000000, end: 75600000 },
      Sat: { start: 36000000, end: 75600000 },
      Sun: { start: 36000000, end: 75600000 },
    },
    interval: 1800000,
  },
  aboutUs: [
    {
      header: 'News',
      body: 'Yeni 2023 Kolleksiyası artıq salonumuzda! Gəlinlik götürən hər kəsə Gəlin Bəzədilməsi ( saç düzümü, makiyaj, üz-qaş,tac) HƏDİYYƏ🎁',
      icon: <icons.IoLogoDesignernews />,
    },
    {
      header: 'Info',
      body: 'Gəlinlər üçün dırnaq paketi: 👍Manikür şellak 👍Pedikür şellak👍 Xına art. Manikür və Pedikürdə istənilən dizayn daxil. Xına artda qara və ya ağ xına seçə bilərsiz.',
      icon: <icons.IoMdListBox />,
    },
    {
      header: 'Paket',
      body: 'Gəlin bəzədilməsi paketləri: 👍150 azn (saç düzümü, makiyaj, üz-qaş və TAC)💕Xına, nişan, nigah paketi -150azn',
      icon: <icons.IoIosBasket />,
    },
  ],
  services: {
    currency: 'AZN',
    services: [
      {
        serviceName: 'Bridal Model',
        imageSrc: '/img/lk1-2.jpg',
        description:
          'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
        price: 0,
        duration: '00:15',
      },
      {
        serviceName: 'Bridal Model 2',
        imageSrc: '/img/lk2-2.jpg',
        description:
          'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
        price: 0,
        duration: '00:15',
      },
      {
        serviceName: 'Bridal Model 3',
        imageSrc: '/img/lk3-2.jpg',
        description:
          'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
        price: 0,
        duration: '00:15',
      },
      {
        serviceName: 'Bridal Model 4',
        imageSrc: '/img/lk4-2.jpg',
        description:
          'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
        price: 0,
        duration: '00:15',
      },
      {
        serviceName: 'Bridal Model 5',
        imageSrc: '/img/lk5-2.jpg',
        description:
          'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
        price: 0,
        duration: '00:15',
      },
      {
        serviceName: 'Bridal Model 6',
        imageSrc: '/img/lk6-2.jpg',
        description:
          'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
        price: 0,
        duration: '00:25',
      },
      {
        serviceName: 'Bridal Model 7',
        imageSrc: '/img/lk7-2.jpg',
        description:
          'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
        price: 0,
        duration: '00:15',
      },
      {
        serviceName: 'Bridal Model 8',
        imageSrc: '/img/lk8-2.jpg',
        description:
          'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
        price: 0,
        duration: '00:15',
      },
      {
        serviceName: 'Bridal Model 9',
        imageSrc: '/img/lk9-2.jpg',
        description:
          'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
        price: 0,
        duration: '00:15',
      },
      {
        serviceName: 'Bridal Model 10',
        imageSrc: '/img/lk10-2.jpg',
        description:
          'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
        price: 0,
        duration: '00:15',
      },
      {
        serviceName: 'Bridal Model 11',
        imageSrc: '/img/lk11-2.jpg',
        description:
          'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
        price: 0,
        duration: '00:15',
      },
      {
        serviceName: 'Bridal Model 12',
        imageSrc: '/img/lk12-2.jpg',
        description:
          'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
        price: 0,
        duration: '00:15',
      },
      {
        serviceName: 'Bridal Model 13',
        imageSrc: '/img/lk13-2.jpg',
        description:
          'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
        price: 0,
        duration: '00:15',
      },
      {
        serviceName: 'Bridal Model 14',
        imageSrc: '/img/lk14-2.jpg',
        description:
          'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
        price: 0,
        duration: '00:15',
      },
      {
        serviceName: 'Bridal Model 15',
        imageSrc: '/img/lk15-2.jpg',
        description:
          'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
        price: 0,
        duration: '00:15',
      },
      {
        serviceName: 'Bridal Model 16',
        imageSrc: '/img/lk16-2.jpg',
        description:
          'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
        price: 0,
        duration: '00:15',
      },
    ],
  },
  reviews: [
    {
      title: 'Lalə Quluzade Elekberova',
      body: 'Bahar ve Esmer xanim super👍',
    },
  ],
};

export default constants;

////////////////////////////////////////////////////////////////////////////////COMPONENTS
import React from 'react';
import { CustomCarousel } from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const Reviews = () => (
  <section id='reviews-section' className='page p-t-m p-b-m'>
    <h1 className='t-align-c'>Rəylər</h1>
    <CustomCarousel items={constants.reviews} fade={false} indicators={false} />
  </section>
);

export default Reviews;

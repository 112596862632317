///////////////////////////////////////////////////////////////////////////////////MODULES
import { Link } from 'react-router-dom';
//////////////////////////////////////////////////////////////////////////////////////////

const NotFoundPage = () => (
  <section className='below-header p-b-m page' style={{ minHeight: '100vh' }}>
    <h1 className='t-align-c'>404 Not Found 😔</h1>
    <h1 className='t-align-c'>
      <Link to='/'>Go Home</Link>
    </h1>
  </section>
);

export default NotFoundPage;

////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { MainImage } from '@ibiliaze/reactstrap';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import About from '../Dashboard/About';
import Reviews from '../Dashboard/Reviews';
import Services from '../Dashboard/Services';
import Banner from '../Dashboard/Banner';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const HomePage = () => (
  <>
    <MainImage
      whiteButton={false}
      imgPath={constants.mainPic}
      facebookHandle={constants.facebook}
      instagramHandle={constants.instagram}
      youtubeChannelId={constants.youtube}
      slogan={constants.slogan}
      title={constants.cust.toUpperCase()}
      onButtonClick={_ => window.open(`https://wa.me/${constants.phone1}`)}
      buttonText={constants.mainPicButton}
      infoTop='60%'
      imgHeight='500px'
      imgMobileHeight='300px'
    />
    <Banner />
    <About />
    <Services />
    <Reviews />
  </>
);

export default HomePage;
